<template>
  <div
    class="relative"
    :class="containerClass"
  >
    <slot name="label">
      <label
        v-if="label"
        :class="labelClass"
      >
        {{ label }}
        <span
          v-if="isRequired"
          class="text-primary-red"
        >*</span>
      </label>
    </slot>
    <div
      class="w-full"
      :class="inputContainerClass"
    >
      <div
        class="relative w-full"
        :class="inputInnerContainerClass"
      >
        <component
          :is="textArea ? 'textarea' : 'input'"
          :value="inputValue"
          :class="[
            error ? 'error' : '',
            textArea ? textAreaResizeClass : '',
            variant,
            inputSize
          ]"
          v-bind="attrs"
          :disabled="disabled"
          class="block h-full w-full focus:outline-none focus:ring-0"
          @input="handleInput"
          @keyup.enter="handleEnter"
        />
        <slot name="errorIcon">
          <base-svg
            v-if="error"
            src="icons/errorCircle.svg"
            class="text-red-600 h-4 w-4 absolute right-3"
            :class="textArea ? 'top-2' : 'bottom-1/2 transform translate-y-1/2'"
            :svg-attributes="{
              class: 'w-full h-full'
            }"
            tag="div"
          />
        </slot>
      </div>
      <small
        v-if="error && errorText === ''"
        class="block mb-2 text-red-600"
      >
        {{ error }}
      </small>
      <small
        v-if="errorText !== '' && error"
        class="block mb-2 text-red-600"
      >
        {{ errorText }}
      </small>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import BaseSvg from './BaseSvg.vue';

export default {
    name: 'BaseInput',

    components: {
        BaseSvg
    },

    inheritAttrs: false,

    props: {
        errorText: {
            type: String,
            default: '',
            description: 'error message to show under required field'
        },
        error: {
            type: String,
            default: '',
            description: 'error message for switching layout of input to error layout'
        },
        label: {
            type: String,
            default: '',
            description: 'label for input(for custom label, use slot)'
        },
        modelValue: {
            type: [String, Number],
            default: '',
            description: 'bound input value'
        },
        containerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to root element'
        },
        inputContainerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to input container element'
        },
        inputInnerContainerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to input inner container element'
        },
        textArea: {
            type: Boolean,
            default: false,
            description: 'textarea is rendered instead of input element if set to true'
        },
        textAreaResizeClass: {
            type: String,
            default: 'resize-none',
            description: 'class for controlling textarea resize behaviour (resizing disabled by default)'
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'disables component if set to true'
        },
        disabledClass: {
            type: String,
            default: 'disabled',
            description: 'css classes applied on component in disabled state'
        },
        isRequired: {
            type: Boolean,
            default: false,
            description: 'adds asterisk to label to denote required field'
        },
        labelClass: {
            type: String,
            default: 'block text-sm text-black mb-1',
            description: 'css classes applied on label'
        },
        variant: {
            type: String,
            default: 'input-default',
            description: 'input variant (check variants in style section)'
        },
        inputSize: {
            type: String,
            default: 'px-2 py-1',
            description: 'input element size (use padding classes to control size)'
        }
    },

    emits: [
        'update:modelValue',
        'enterPressed'
    ],

    setup (props, { attrs, emit }) {
        const inputValue = ref('');

        onMounted(() => {
            inputValue.value = props.modelValue;
        });

        watch(
            () => props.modelValue,
            () => {
                inputValue.value = props.modelValue;
            }
        );

        const handleInput = (event) => {
            inputValue.value = event.target.value;
            emit('update:modelValue', inputValue.value);
        };

        const handleEnter = (event) => {
            inputValue.value = event.target.value;
            emit('enterPressed', inputValue.value);
        };

        return {
            attrs,
            inputValue,
            handleInput,
            handleEnter
        };
    }
};
</script>

<style scoped>
@layer components {
  /* input variants */
  .input-default {
      @apply border border-custom-gray-8 text-custom-gray-7 rounded-sm text-sm;
  }
  .input-gray {
      @apply border border-custom-gray-5 bg-white text-custom-gray-7 rounded-sm text-sm;
  }
  .input-primary-red {
      @apply border border-primary-red bg-white rounded-sm text-sm;
  }

  /* input state styling */
  .error {
      @apply border border-red-600;
  }
  input, textarea {
    &:disabled {
      @apply bg-custom-gray-6 cursor-not-allowed opacity-70;
    }
    &:focus {
        @apply focus:border-blue-600;
    }
  }

  /* label variants */
  .label-w-46 {
      @apply block text-sm text-black mr-3.5 w-46 flex-shrink-0;
  }
  .label-w-36 {
      @apply block text-sm text-black mr-3.5 w-36 flex-shrink-0;
  }
}
</style>
